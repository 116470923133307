.container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 960px;
  min-height: 100vh;
  padding: 80px 32px 16px;
  margin: 0 auto;
  line-height: 1.5;
  background-color: var(--color-dialog);
  @media screen and (min-width: 1200px) {
    & {
      padding-left: calc(var(--padding) * 6);
      padding-right: calc(var(--padding) * 6);
    }
  }
  @media screen and (max-width: 1200px) {
    & {
      padding-left: calc(var(--padding) * 4);
      padding-right: calc(var(--padding) * 4);
    }
  }
  @media screen and (max-width: 1000px) {
    & {
      width: 100%;
    }
  }
  @media screen and (max-width: 600px) {
    & {
      padding-left: calc(var(--padding) * 2);
      padding-right: calc(var(--padding) * 2);
    }
  }
}
