.icon {
  position: relative;
  top: 0.12em;
  display: inline-flex;
  align-items: center;
  svg {
    width: 1em;
    height: 1em;
  }
  span {
    padding-left: var(--padding, 8px);
    font-size: var(--font-size, 20px);
    text-transform: uppercase;
  }
}
