.select {
  color: var(--color-primary);
  display: inline-flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
}
.bg {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: transparent;
}
.dropdown {
  position: absolute;
  z-index: 2;
  box-sizing: border-box;
  right: 0px;
  top: 40px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 8px var(--color-shadow);
  width: 110px;
  padding: var(--padding, 8px) 0;
  color: var(--color-primary);
  background-color: var(--color-dialog);
  border-radius: var(--radius, 8px);
  border: 1px solid var(--color-border);
  transition: all var(--timer, 0.3s);
  &.hidden {
    opacity: 0;
    transform: scale(0%) translate(60%, -60%);
  }
  button {
    border-width: 0px !important;
    border-radius: 0 !important;
    margin: 0 !important;
    padding: 6px 16px !important;
    font-weight: 600;
    text-align: left;
    &:hover {
      background-color: var(--color-body);
    }
  }
}
