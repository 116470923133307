::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-border);
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background-color: var(--color-dialog);
  border-radius: 5px;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  font-family: notosans, sans-serif;
  font-size: var(--font-size, 20px);
  font-weight: 400;
  line-height: 1.5;
  color: var(--color-secondary);
  background-color: var(--color-body);
  overflow-y: scroll;
  transition: all var(--timer);
}
hr {
  margin: var(--padding, 8px) calc(-1 * var(--padding, 8px));
  // border: none;
  padding: calc(var(--padding, 8px) + var(--border, 1px));
  @extend %bd;
}
h1,
h2,
h3,
h4 {
  font-family: montserrat, sans-serif;
  letter-spacing: 1.5px;
  font-weight: 700;
  color: var(--color-primary);
}
h1 {
  font-size: var(--font-size-h1, 28px);
}
h2 {
  font-size: var(--font-size-h2, 24px);
}
h3 {
  text-transform: uppercase;
  font-weight: 500;
}
button,
input,
textarea {
  font: inherit;
  color: inherit;
  outline: none;
  border: none;
  background: transparent;
}
input[type='text'],
input[type='password'],
input[type='email'],
select {
  width: 100%;
  font-size: inherit;
}
input[type='checkbox'] {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  position: relative;
  &:checked::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(var(--color-msg-error), 0.35);
  }
}
input {
  display: block;
  border: 1px solid var(--color-border);
  border-radius: var(--radius, 8px);
  padding: 8px 12px;
  color: var(--color-primary);
  background: var(--color-input);
  &:focus {
    background: var(--color-active);
    border-color: var(--color-disabled);
  }
}
a,
button {
  color: inherit;
  background: transparent;
  letter-spacing: 0.1rem;
  transition: all var(--timer, 0.3s);
  white-space: nowrap;
  &.underline {
    font-weight: 500;
    color: var(--color-primary);
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      transition: all 0.3s;
      background-color: var(--color-primary);
      border-radius: 1px;
    }
    &:hover {
      color: var(--color-msg-default);
      &::after {
        background-color: var(--color-msg-default);
      }
    }
    &.active {
      color: var(--color-brand);
      &:after {
        background-color: var(--color-brand);
      }
      &:hover {
        color: var(--color-primary);
        &::after {
          background-color: var(--color-primary);
        }
      }
    }
  }
  &.active {
    color: var(--color-brand) !important;
    &:after {
      background-color: var(--color-brand);
    }
    &:hover {
      color: var(--color-primary);
      &::after {
        background-color: var(--color-primary);
      }
    }
  }
}

section {
  padding: 30px 45px;
  margin: var(--padding, 8px) 0;
  @extend %bd;
  border-radius: 28px;
  &::before {
    border-radius: 20px;
  }
}
svg,
img,
fieldset,
legend {
  pointer-events: none;
  flex-shrink: 0;
}
svg {
  fill: currentColor;
  overflow: visible !important;
  transition: fill var(--timer, 0.3s) / 6 cubic-bezier(0.4, 0, 0.2, 1) var(--timer, 0.3s) / 2;
  user-select: none;
  vertical-align: middle;
}
img {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: var(--radius, 8px);
  object-fit: cover;
  transition: all var(--timer, 0.3s);
  background-color: var(--color-primary);
}

small {
  &.green {
    color: var(--color-msg-success);
  }
  &.red {
    color: var(--color-msg-error);
  }
  &.blue {
    color: var(--color-msg-info);
  }
  &.gray {
    color: var(--color-secondary);
  }
}

*[disabled] {
  @extend %disabled;
}
